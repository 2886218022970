import React, { useState, useEffect, useRef } from 'react';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { OverviewFeature } from './Features/OverviewFeature';
import { CtaCard } from '../General/CtaCard';
import { BodyFeatureCard } from './Features/BodyFeatureCard';
import { BodyFeature } from './Features/BodyFeature';
import { Testimonials } from '../Testimonials';
import { MobileFeatures } from './Features/Mobile/MobileFeatures';
// import { TestimonialCarousel } from '../Testimonials/TestimonialCarousel';
import { RmSpecialSection } from './Features/RmSpecialSection';
import { CustomerLogos } from '../CustomerLogos';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';

const TestimonialCarousel = loadable(() =>
  import('../../components/Testimonials/TestimonialCarousel')
);

const useStyles = makeStyles((theme) => ({
  testimonialBackground: {
    backgroundSize: 'cover',
    height: '600px',
    width: '99vw',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'left 60px',
    },
  },
  testimonialContainer: {
    marginTop: '-35rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-30rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-25rem',
    },
  },
  testimonialTitle: {
    color: theme.white,
    fontWeight: 700,
    padding: '2rem 0',
  },
}));

export const IndustryBody = ({
  industry,
  iosLogo,
  androidLogo,
  qbLogo,
  zapierLogo,
  shopifyLogo,
  setFeatureModalOpen,
}) => {
  const classes = useStyles();

  const formRef = useRef();
  const featureRef = useRef(null);

  //card array that gets modified on click. set brand new array to be manipulated on mount
  const [cardArray, setCardArray] = useState([]);

  useEffect(() => {
    setCardArray(industry.featureSection);
  }, []);

  const setToFirst = (selected) => {
    const filteredArray = cardArray.filter(
      (item) => item._key !== selected._key
    );
    setCardArray([selected, ...filteredArray]);
  };

  //handles scrolling to selected feature => currently pointed at the div above the cards on desktop view
  const handleSelectFeature = async (e, content) => {
    await setSelectedFeature(content._key);
    setToFirst(content);
    featureRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  //state for selected card for features
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    setSelectedFeature(industry.featureSection[0]?._key);
  }, []);

  const med = useMediaQuery('(max-width: 960px)');
  const lg = useMediaQuery('(max-width: 1280px)');

  const {
    overviewHeader,
    _rawOverviewBody,
    industryCallout,
    featureSection,
    featureHeader,
    accentColor,
    softwareFeatures,
    testimonial,
    customerLogos,
    customerLogosHeader,
    rmSpecialFeature,
    overviewImage,
    testimonialHeader,
    testimonialTitle,
    testimonialBackground,
  } = industry;

  const firstSet = cardArray.length === 4 ? 2 : 3;
  const lastSet =
    cardArray.length === 4
      ? -2
      : cardArray.length === 6
      ? -3
      : cardArray.length === 5
      ? -2
      : null;
  const longPage = industry.featureSection.length > 2;
  return (
    <>
      <Container>
        {overviewHeader ? (
          <OverviewFeature
            overviewHeader={overviewHeader}
            overviewImage={overviewImage}
            overviewBody={_rawOverviewBody}
          />
        ) : null}

        {industryCallout?.ctaHeader ? (
          <CtaCard
            background={industryCallout.ctaBackground}
            header={industryCallout.ctaHeader}
            subheader={industryCallout.ctaSubheader}
            ctaText={industryCallout.ctaText}
            internalLink={industryCallout.internalLink}
            ctaLink={industryCallout.ctaLink}
          />
        ) : null}
        {med ? (
          <MobileFeatures
            featureSection={featureSection}
            featureHeader={featureHeader}
            featureRef={featureRef}
            selectedFeature={selectedFeature}
            accentColor={accentColor[0].hexValue ?? '#002D5C'}
            iosLogo={iosLogo}
            androidLogo={androidLogo}
            qbLogo={qbLogo}
            featureListCta={softwareFeatures?.featureListCta}
            setFeatureModalOpen={setFeatureModalOpen}
          />
        ) : (
          longPage && (
            <BodyFeatureCard
              featureHeader={featureHeader}
              featureSection={featureSection}
              selectedFeature={selectedFeature}
              handleClick={handleSelectFeature}
              featureRef={featureRef}
              featureListCta={softwareFeatures?.featureListCta}
              setFeatureModalOpen={setFeatureModalOpen}
              accentColor={accentColor[0].hexValue ?? '#002D5C'}
            />
          )
        )}
      </Container>

      <Container>
        {!med &&
          cardArray
            .slice(0, firstSet)
            .map((feature, index) => (
              <BodyFeature
                feature={feature}
                index={index}
                key={index}
                selectedFeature={selectedFeature}
                accentColor={accentColor[0].hexValue}
                iosLogo={iosLogo}
                androidLogo={androidLogo}
                qbLogo={qbLogo}
                setSelectedFeature={setSelectedFeature}
              />
            ))}
      </Container>
      {/* should change them all to work this way. pass the background prop to render white header instead of workwave blue */}
      <div
        className={classes.testimonialBackground}
        style={{
          backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
        }}
      />
      <WaveUpSVG fill='#FFF' />
      <div className={classes.testimonialContainer}>
        <Testimonials
          header={testimonialTitle}
          testimonials={testimonial}
          formRef={formRef}
        />
      </div>
      <Container>
        <CustomerLogos
          customerLogosArray={customerLogos}
          customerLogosHeader={customerLogosHeader}
        />
      </Container>
      <div className={classes.featureBackground}>
        <Container>
          {!med &&
            cardArray
              .slice(lastSet)
              .map((feature, index) => (
                <BodyFeature
                  feature={feature}
                  index={index}
                  key={index}
                  selectedFeature={selectedFeature}
                  accentColor={accentColor[0].hexValue}
                  iosLogo={iosLogo}
                  androidLogo={androidLogo}
                  qbLogo={qbLogo}
                  setSelectedFeature={setSelectedFeature}
                />
              ))}
          {rmSpecialFeature && (
            <RmSpecialSection
              feature={rmSpecialFeature}
              accentColor={accentColor[0].hexValue}
              zapierLogo={zapierLogo}
              shopifyLogo={shopifyLogo}
            />
          )}
        </Container>
      </div>
    </>
  );
};
